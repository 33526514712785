import { NoteContextComponent } from '../../modules/note/pc/NoteContext';
import { NoteModule } from '../../modules/note/pc';

const NoteListPCPage = () => {
	return (
		<NoteContextComponent>
			<NoteModule />
		</NoteContextComponent>
	);
};

export default NoteListPCPage;
